import { config } from "../config";
import { fetchResponseToJson } from "../helpers/serviceHelpers";
import Account from "../models/account";
import Attribute from "../models/attribute";
import Contact from "../models/contact";
import Partner from "../models/partner";
import EntityTypes from "../models/entityTypes";
import FileBlob from "../models/fileBlob";
import Project, { ProjectFromApi } from "../models/project";
import { LabelValue } from "./labelsSlice";
import User from "../models/user";
import Resource from "../models/resource";
import Paginated from "../models/paginated";
import ProjectResource, { ProjectResourceStatus } from "../models/projectResource";
import Review from "../models/review";
import Reviewer from "../models/reviewer";
import ProjectGrade from "../models/projectGrade";
import ProjectReviewer from "../models/projectReviewer";
import GradeTypes from "../models/gradeTypes";
import Tag from "../models/tag";
import LoginRequest from "../models/loginRequest";
import LoginResponse from "../models/loginResponse";
import UnitEvaluation from "../models/unitEvaluation";
import GradeUnit from "../models/gradeUnit";
import TodoProjectSummary from "../models/todoProjectSummary";
import { ReportUnit, ReportUnitHeatmap, ReportUnitResource } from "../models/reportUnit";
import { Heatmap } from "../models/heatmap";
import ShareRequest from "../models/shareRequest";
import ShareResponse from "../models/shareResponse";

// export interface SearchApi<T> (term: string, page?: number, perPage?: number) => Promise<T[]>;
export type SearchProps = {
    search?: string;
    active?: boolean | null;
    page?: number;
    perPage?: number;
    sort?: string;
    sortDescending?: boolean;
    params?: URLSearchParams;
}
export type CountProps = {
    active?: boolean;
    mineOnly?: boolean;
}
export type SearchApi<T> = (props: SearchProps) => Promise<Paginated<T>>;

const apis = new Map<EntityTypes, string>();
apis.set(EntityTypes.Contact, 'contacts');
apis.set(EntityTypes.Project, 'projects');
apis.set(EntityTypes.ProjectResource, 'projectResources');
apis.set(EntityTypes.Resource, 'resources');
apis.set(EntityTypes.Partner, 'partners');

export const api = {
    loginGoogleUrl,
    accounts: {
        get: accountsGet,
    },
    create: <T>(entityType: EntityTypes, record: T) => post<T, T>(apis.get(entityType)!, record),
    settings: {
        labels: {
            update: (type: string, value: LabelValue) => patch<LabelValue>('labels', type!, value),
        },
        tags: {
            //search: (props: SearchProps) => paginatedList<Tag>('tags', props),
            all: () => list<Tag>('tags/all'),
            search: (props: SearchProps & { parentTagId?: number, includeDisabled?: boolean }) => {
                const params = props.params ?? new URLSearchParams();
                if (props.parentTagId) {
                    params.set('parentTagId', `${props.parentTagId}`);
                }
                if (props.includeDisabled) {
                    params.set('includeDisabled', `${props.includeDisabled}`);
                }
                props.params = params;
                return paginatedList<Tag>(`tags`, props);
            },
            searchDomains: (props: SearchProps) => {
                const params = props.params ?? new URLSearchParams();
                params.set('head', 'true');
                props.params = params;
                return paginatedList<Tag>(`tags`, props);
            },
            get: (id: number) => getById<Tag>('tags', id),
            subtags: (id: number) => get<Tag[]>(`tags/${id}/subtags`),
            sort: (tags: Tag[]) => post<Tag[], Tag[]>('tags/sort', tags),
            export: (id: number) => get<FileBlob>(`tags/${id}/export`),
            create: (attribute: Tag) => post<Tag, Tag>('tags', attribute),
            update: (attribute: Tag) => patch<Tag>('tags', attribute.id!, attribute),
        },
    },
    account: {
        attributes: {
            list: () => list<Attribute>('attributes'),
            sort: (attributes: Attribute[]) => patch<Attribute[]>('attributes', 'sort', attributes),
            forEntity: (entityType: EntityTypes, includeDisabled?: boolean) => includeDisabled ? list<Attribute>(`attributes/entity/${entityType}?includeDisabled=true`) : list<Attribute>(`attributes/entity/${entityType}`),
            get: (id: number) => getById<Attribute>('attributes', id),
            create: (attribute: Attribute) => post<Attribute, Attribute>('attributes', attribute),
            update: (attribute: Attribute) => patch<Attribute>('attributes', attribute.id!, attribute),
        }
    },
    partners: {
        count: (props?: CountProps) => count('partners', props),
        list: () => list<Partner>('partners'),
        //search: (term: string) => list<Partner>('partners', term),
        search: (props: SearchProps) => paginatedList<Partner>('partners', props),
        get: (id: number) => getById<Partner>('partners', id),
        create: (customer: Partner) => post<Partner, Partner>('partners', customer),
        update: (partner: Partner) => patch<Partner>('partners', partner.id!, partner),
    },
    project: (projectId: number) => {
        return {
            export: () => get<FileBlob>(`projects/${projectId}/export`),
            share: () => get<ShareRequest>(`projects/${projectId}/share`),
            search: (props: SearchProps) => paginatedList<Reviewer>(`projects/${projectId}/reviewers`, props),
            resources: (props: SearchProps) => paginatedList<Resource>(`resources/project/${projectId}`, props),
            grades: () => get<ProjectGrade[]>(`projects/${projectId}/grades`),
            grade: (grade: GradeTypes) => {
                return {
                    units: () => get<GradeUnit[]>(`projects/${projectId}/grades/${grade}/units`),
                    unit: (unit: string) => {
                        return {
                            create: () => post<GradeUnit, GradeUnit>(`projects/${projectId}/grades/${grade}/units`, { projectId, grade, unit }),
                            resources: () => get<ProjectResource[]>(`projectResources/project/${projectId}/grade/${grade}/unit/${unit}`),
                        };
                    },
                };
            },
            //units: (grade: GradeTypes) => list<GradeUnit>(`projects/${projectId}/grades/${grade}/units`)
        }
    },
    gradeUnit: {
        get: (id: number) => getById<GradeUnit>('gradeUnits', id),
        projectReviewer: (id: number) => get<ProjectReviewer>(`gradeUnits/${id}/reviewer`),
    },
    projects: {
        count: (props?: CountProps) => count('projects', props),
        list: () => list<Project>('projects')
            .then((projects) => projects.map((project) => ProjectFromApi(project))),
        forPartner: (partnerId: number) => list<Project>(`projects/partner/${partnerId}`),
        forUser: (props: SearchProps & { userId: string }) => paginatedList<Project>(`projects/user/${props.userId}`, props),
        get: (id: number) => getById<Project>('projects', id)
            .then((project) => ProjectFromApi(project)),
        //getUnitSummary: (id: number) => get<ProjectUnitSummary[]>(`projects/${id}/unitSummary`),
        getGrades: (id: number) => get<ProjectGrade[]>(`projects/${id}/grades`),
        create: (project: Project) => post<Project, Project>('projects', project)
            .then((project) => ProjectFromApi(project)),
        update: (project: Project) => patch<Project>('projects', project.id!, project)
            .then((project) => ProjectFromApi(project)),
        search: (props: SearchProps) => paginatedList<Project>('projects', props),
        searchRecent: (props: SearchProps) => {
            const params = props.params ?? new URLSearchParams();
            params.set('recent', 'true');
            props.params = params;
            return paginatedList<ProjectResource>(`projects`, props);
        },
        searchMine: (props: SearchProps) => {
            const params = props.params ?? new URLSearchParams();
            params.set('mine', 'true');
            props.params = params;
            return paginatedList<ProjectResource>(`projects`, props);
        },
    },
    resources: {
        count: (props?: CountProps) => count('resources', props),
        //forProject: (projectId: number) => list<Resource>(`resources/project/${projectId}`),
        search: (props: SearchProps) => paginatedList<Project>('resources', props),
        searchMine: (props: SearchProps) => paginatedList<Project>('resources/mine', props),
        get: (id: number) => getById<Resource>('resources', id),
        //translationsFor: (id: number, props: SearchProps) => paginatedList<Review>(`resources/${id}/translations`, props),
        create: (resource: Resource) => post<Resource, Resource>('resources', resource),
        update: (resource: Resource) => patch<Resource>('resources', resource.id!, resource),
        translations: (resource: Resource) => {
            return {
                count: (props?: CountProps) => count(`resources/${resource.id}/translations`, props),
                search: (props: SearchProps) => paginatedList<Review>(`resources/${resource.id}/translations`, props),
                create: (resourceTranslation: Resource) => post<Resource, Resource>(`resources/${resource.id}/translations`, resourceTranslation),
                //update: (resource: Resource) => patch<Resource>('resources', resource.id!, resource),
            };
        },
    },
    projectResources: {
        todo: (props: SearchProps & { projectId?: number, grade?: string }) => {
            const params = props.params ?? new URLSearchParams();
            if (props.grade != null) {
                params.set('grade', props.grade);
            }
            if (props.projectId != null) {
                params.set('projectId', props.projectId.toString());
            }
            props.params = params;
            return paginatedList<Review>(`projectResources/todo`, props);
        },
        forProject: (props: SearchProps & { projectId: number, grade?: GradeTypes, unit?: string, status?: ProjectResourceStatus }) => {
            const params = props.params ?? new URLSearchParams();
            if (props.grade != null) {
                params.set('grade', props.grade.toString());
            }
            if (props.unit != null) {
                params.set('unit', props.unit);
            }
            if (props.status) {
                params.set('status', props.status.toString());
            }
            props.params = params;
            return paginatedList<ProjectResource>(`projectResources/project/${props.projectId}`, props);
        },
        forResource: (props: SearchProps & { resourceId: number, }) => paginatedList<ProjectResource>(`projectResources/resource/${props.resourceId}`, props),
        create: (projectResource: ProjectResource) => post<ProjectResource, ProjectResource>('projectResources', projectResource),
        update: (projectResource: ProjectResource) => patch<ProjectResource>('projectResources', projectResource.id!, projectResource),
        get: (id: number) => getById<ProjectResource>('projectResources', id),
        clonableReviews: (id: number) => get<Review[]>(`projectResources/${id}/clonableReviews`),

    },
    reviewers: {
        create: (projectReviewer: ProjectReviewer) => post<ProjectReviewer, ProjectReviewer>(`projects/${projectReviewer.projectId}/reviewers`, projectReviewer),
        update: (projectReviewer: ProjectReviewer) => patch<ProjectReviewer>(`projects/${projectReviewer.projectId}/reviewers`, projectReviewer.id!, projectReviewer),
        get: (id: number) => getById<ProjectReviewer>('projectReviewers', id),
        search: (props: SearchProps & { projectId: number }) => paginatedList<Contact>(`projects/${props.projectId}/reviewers`, props),
        //search: (projectId: number, term?: string, page?: number, perPage?: number, params?: URLSearchParams) => paginatedList<ProjectReviewer>(`projects/${projectId}/reviewers`, term, page, perPage, params),
        forProject: (props: SearchProps & { projectId: number, grade?: string }) => {
            const params = props.params ?? new URLSearchParams();
            if (props.grade != null) {
                params.set('grade', props.grade);
                params.set('sort', 'created');
            }
            props.params = params;
            return paginatedList<ProjectReviewer>(`projects/${props.projectId}/reviewers`, props);
        },
    },
    contacts: {
        list: () => list<Contact>('contacts'),
        forPartner: (partnerId: string) => list<Contact>(`contacts/partner/${partnerId}`),
        forProject: (projectId: string) => list<Contact>(`contacts/project/${projectId}`),
        // search: (term: string) => list<Contact>('contacts', term),
        search: (props: SearchProps) => paginatedList<Contact>('contacts', props),
        get: (id: number) => getById<Contact>('contacts', id),
        create: (contact: Contact) => post<Contact, Contact>('contacts', contact),
        update: (contact: Contact) => patch<Contact>('contacts', contact.id!, contact),
    },
    users: {
        list: () => list<User>('users'),
        // search: (term: string) => list<User>('users', term),
        search: (props: SearchProps) => paginatedList<User>('users', props).then((response) => {
            return {
                ...response,
                records: response.records.map((record) => new User(record)),
            };
        }),
        //search: (term?: string, page?: number, perPage?: number) => paginatedList<User>('users', term, page, perPage)
        get: (id: number | string) => getById<User>('users', id).then((response) => new User(response)),
        create: (user: User) => post<User, User>('users', user),
        update: (user: User) => patch<User>('users', user.id!, user),
    },
    files: {
        // search: (term: string) => list<FileBlob>('files', term),
        //search: (term?: string, page?: number, perPage?: number) => paginatedList<FileBlob>('files', term, page, perPage),
        search: (props: SearchProps) => paginatedList<FileBlob>('files', props),
        upload: (id: number, file: File, listener?: UploadListener) => upload<FileBlob>('files', id, file, listener),
        create: (file: FileBlob) => post<FileBlob, FileBlob>('files', file),
    },
    reviews: {
        //forProject: (projectId: number, term?: string, page?: number, perPage?: number, grade?: string) => {
        forProject: (props: SearchProps & { projectId: number, grade?: string, unit?: string, mine?: boolean }) => {
            const params = new URLSearchParams();
            if (props.mine) {
                params.set('mine', 'true');
                params.set('sort', 'created');
            }
            if (props.grade != null) {
                params.set('grade', props.grade);
                params.set('sort', 'created');
            }
            if (props.unit != null) {
                params.set('unit', props.unit);
            }
            props.params = params;
            return paginatedList<Review>(`reviews/project/${props.projectId}`, props);
        },
        forUser: (props: SearchProps & { userId: string, grade?: string, unit?: string, mine?: boolean }) => {
            const params = new URLSearchParams();
            if (props.mine) {
                params.set('mine', 'true');
                params.set('sort', 'created');
            }
            if (props.grade != null) {
                params.set('grade', props.grade);
                params.set('sort', 'created');
            }
            if (props.unit != null) {
                params.set('unit', props.unit);
            }
            props.params = params;
            return paginatedList<Review>(`reviews/user/${props.userId}`, props);
        },
        forResource: (props: SearchProps & { resourceId: number, mine?: boolean }) => {
            const params = new URLSearchParams();
            if (props.mine) {
                params.set('mine', 'true');
                params.set('sort', 'created');
            }
            props.params = params;
            return paginatedList<Review>(`reviews/resource/${props.resourceId}`, props);
        },
        recent: (props: SearchProps & { projectId?: number, grade?: string }) => {
            const params = props.params ?? new URLSearchParams();
            params.set('assigned', 'me');
            if (props.grade != null) {
                params.set('grade', props.grade);
            }
            if (props.projectId != null) {
                params.set('projectId', props.projectId.toString());
            }
            props.params = params;
            return paginatedList<Review>(`reviews`, props);
        },
        count: (props?: CountProps) => count('reviews', props),
        //search: (term?: string, page?: number, perPage?: number) => paginatedList<Review>('reviews', term, page, perPage),
        search: (props: SearchProps & { mine?: boolean }) => {
            const params = new URLSearchParams();
            if (props.mine) {
                params.set('mine', 'true');
                params.set('sort', 'created');
            }
            props.params = params;
            return paginatedList<Review>('reviews', props);
        },
        //=> paginatedList<Review>('reviews', props),
        get: (id: number) => getById<Review>('reviews', id),
        create: (record: Review) => post<Review, Review>('reviews', record),
        update: (record: Review) => patch<Review>('reviews', record.id!, record),
        delete: (recordId: number) => httpDelete<Review>('reviews', recordId),
    },
    todo: {
        projects: (props: SearchProps & { projectId?: number }) => {
            const params = props.params ?? new URLSearchParams();
            if (props.projectId != null) {
                params.set('projectId', props.projectId.toString());
            }
            props.params = params;
            return paginatedList<TodoProjectSummary>(`todo/projects`, props);
        },
        forProject: (props: SearchProps & { projectId: number, grade?: string }) => {
            const params = new URLSearchParams();
            if (props.grade != null) {
                params.set('grade', props.grade);
                params.set('sort', 'created');
            }
            props.params = params;
            return paginatedList<ProjectResource>(`todo/project/${props.projectId}/resources`, props);
        },
        unitEvaluations: (props: SearchProps & { projectId: number, grade?: string }) => {
            const params = new URLSearchParams();
            if (props.grade != null) {
                params.set('grade', props.grade);
                params.set('sort', 'created');
            }
            props.params = params;
            return paginatedList<GradeUnit>(`todo/project/${props.projectId}/unitEvaluations`, props);
        },
    },
    unitEvaluations: {
        search: (props: SearchProps) => paginatedList<UnitEvaluation>('unitEvaluations', props),
        get: (id: number) => getById<UnitEvaluation>('unitEvaluations', id),
        create: (record: UnitEvaluation) => post<UnitEvaluation, UnitEvaluation>('unitEvaluations', record),
        update: (record: UnitEvaluation) => patch<UnitEvaluation>('unitEvaluations', record.id!, record),
        forProject: (props: SearchProps & { projectId: number, grade?: string, mine?: boolean }) => {
            const params = new URLSearchParams();
            if (props.mine) {
                params.set('mine', 'true');
                params.set('sort', 'created');
            }
            if (props.grade != null) {
                params.set('grade', props.grade);
                params.set('sort', 'created');
            }
            props.params = params;
            return paginatedList<UnitEvaluation>(`unitEvaluations/project/${props.projectId}`, props);
        },
        // todo: (props: SearchProps & { projectId?: number, grade?: string }) => {
        //     const params = props.params ?? new URLSearchParams();
        //     if (props.grade != null) {
        //         params.set('grade', props.grade);
        //     }
        //     if (props.projectId != null) {
        //         params.set('projectId', props.projectId.toString());
        //     }
        //     props.params = params;
        //     return paginatedList<GradeUnit>(`unitEvaluations/todo`, props);
        // },
    },
    reports: {
        resource: (projectResource: ProjectResource) =>{
            return {
                quality: () => get<ReportUnitResource>(`reports/resource/${projectResource.id}/quality`),
            };
        },
        unit: (gradeUnit: GradeUnit) => {
            //get<ReportUnit>(`reports/unit/${gradeUnit.id}`),
            return {
                data: () => get<ReportUnit[]>(`reports/unit/${gradeUnit.id}/data`),
                heatmap: (parentTagId: number) => get<ReportUnitHeatmap>(`reports/unit/${gradeUnit.id}/heatmap/${parentTagId}`),
            };
        },
        heatmap: (projectId: number) => {
            return {
                domains: () => get<Tag[]>(`reports/heatmap/${projectId}/domains`),
                heatmap: (domain: Tag) => get<Heatmap>(`reports/heatmap/${projectId}/domain/${domain.id}/heatmap`),
            };
        },
    },
    identity: {
        loginCode: (props: LoginRequest) => post<LoginRequest, LoginResponse>('identity/loginCode', props),
        login: (props: LoginRequest) => post<LoginRequest, LoginResponse>('identity/login', props),
        shared: (props: ShareRequest) => post<ShareRequest, ShareResponse>('identity/shared', props),
    }
};

function loginGoogleUrl(returnUrl?: string): string {
    const url = `${config.apiUrl}/v1/identity/external-login?provider=Google&returnUrl=${returnUrl}`;
    return url;
}

async function accountsGet(): Promise<Account[]> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "GET",
        headers: authHeader,
        credentials: 'include',
    };
    const response = await fetch(`${config.apiUrl}/v1/accounts`, requestOptions);
    if (response.ok) {
        return (await response.json()) as Account[];
    }
    return Promise.reject("Invalid response from server.");
}


async function count(entity: String, props?: CountProps): Promise<number> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "GET",
        headers: authHeader,
        credentials: 'include',
    };
    let url = new URL(`${config.apiUrl}/v1/${entity}/count`);
    if (props?.active) {
        url.searchParams.set('active', props.active ? 'true' : 'false');
    }
    if (props?.mineOnly) {
        url.searchParams.set('mineOnly', 'true');
    }
    // if (null != props.params) {
    //     for (const [key, value] of props.params.entries()) {
    //         url.searchParams.set(key, value);
    //     }
    // }
    // if (active) {
    //     url += '?active=true'
    // }
    const response = await fetch(url, requestOptions);
    if (response.ok) {
        return fetchResponseToJson(response);
    }
    return Promise.reject("Invalid response from server.");
}
async function list<T>(entity: String, search?: string): Promise<T[]> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "GET",
        headers: authHeader,
        credentials: 'include',
    };
    let url = new URL(`${config.apiUrl}/v1/${entity}`);
    if (search) {
        url.searchParams.set('search', search);
    }
    const response = await fetch(url, requestOptions);
    if (response.ok) {
        return fetchResponseToJson(response);
    }
    return Promise.reject("Invalid response from server.");
}
async function paginatedList<T>(path: string, props: SearchProps): Promise<Paginated<T>> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "GET",
        headers: authHeader,
        credentials: 'include',
    };
    let url = new URL(`${config.apiUrl}/v1/${path}`);
    if (props.search) {
        url.searchParams.set('search', props.search);
    }
    if (props.page != null) {
        url.searchParams.set('page', props.page.toString());
    }
    if (props.perPage != null) {
        url.searchParams.set('perPage', props.perPage.toString());
    }
    if (props.sort) {
        url.searchParams.set('sort', props.sort);
    }
    if (props.sortDescending) {
        url.searchParams.set('sortDescending', props.sortDescending ? 'true' : 'false');
    }
    if (props.active !== null) {
        url.searchParams.set('active', (props.active ?? true) ? 'true' : 'false');
    }
    if (null != props.params) {
        for (const [key, value] of props.params.entries()) {
            url.searchParams.set(key, value);
        }
    }
    const response = await fetch(url, requestOptions);
    if (response.ok) {
        return fetchResponseToJson(response);
    }
    return Promise.reject("Invalid response from server.");
}
// async function search<T>(entity: String, search?: string): Promise<T[]> {
//     const authHeader = new Headers();
//     authHeader.append("Content-Type", "application/json");
//     const requestOptions: RequestInit = {
//         method: "GET",
//         headers: authHeader,
//         credentials: 'include',
//     };
//     let url = `${config.apiUrl}/v1/${accountId}/${entity}`;
//     if (search) {
//         url += `?search=${encodeURI(search)}`
//     }
//     const response = await fetch(url, requestOptions);
//     if (response.ok) {
//         return (await response.json()) as T[];
//     }
//     return Promise.reject("Invalid response from server.");
// }
// async function post<T>(entity: String, attribute: T): Promise<T> {
//     const authHeader = new Headers();
//     authHeader.append("Content-Type", "application/json");
//     const requestOptions: RequestInit = {
//         method: "POST",
//         headers: authHeader,
//         credentials: 'include',
//         body: JSON.stringify(attribute),
//     };
//     const response = await fetch(`${config.apiUrl}/v1/${entity}`, requestOptions);
//     if (response.ok) {
//         return (await response.json()) as T;
//     }
//     const responseText = await response.text();
//     return Promise.reject(responseText ?? "Invalid response from server.");
// }
async function post<T, R>(entity: String, request: T): Promise<R> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "POST",
        headers: authHeader,
        credentials: 'include',
        body: JSON.stringify(request),
    };
    const response = await fetch(`${config.apiUrl}/v1/${entity}`, requestOptions);
    if (response.ok) {
        return (await response.json()) as R;
    }
    const responseText = await response.text();
    return Promise.reject(responseText ?? "Invalid response from server.");
}
async function patch<T>(entity: String, id: number | string, data: T): Promise<T> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "PATCH",
        headers: authHeader,
        credentials: 'include',
        body: JSON.stringify(data),
    };
    const response = await fetch(`${config.apiUrl}/v1/${entity}/${id}`, requestOptions);
    if (response.ok) {
        return (await response.json()) as T;
    }
    return Promise.reject("Invalid response from server.");
}
async function httpDelete<T>(entity: String, id: number | string): Promise<T | null> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "DELETE",
        headers: authHeader,
        credentials: 'include',
        //body: JSON.stringify(data),
    };
    const response = await fetch(`${config.apiUrl}/v1/${entity}/${id}`, requestOptions);
    if (response.ok) {
        if (response.status === 204) return null;
        return (await response.json()) as T;
    }
    return Promise.reject("Invalid response from server.");
}
async function getById<T>(entity: String, id: number | string): Promise<T> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "GET",
        headers: authHeader,
        credentials: 'include',
    };
    const response = await fetch(`${config.apiUrl}/v1/${entity}/${id}`, requestOptions);
    if (response.ok) {
        return fetchResponseToJson(response) as T;
        // return fetchResponseToJson((await response.json())) as T;
    }
    return Promise.reject("Invalid response from server.");
}
async function get<T>(path: string): Promise<T> {
    const authHeader = new Headers();
    authHeader.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "GET",
        headers: authHeader,
        credentials: 'include',
    };
    const response = await fetch(`${config.apiUrl}/v1/${path}`, requestOptions);
    if (response.ok) {
        return fetchResponseToJson(response) as T;
        return (await response.json()) as T;
    }
    return Promise.reject("Invalid response from server.");
}
// async function remove<T>(entity: String, id: number | string): Promise<T> {
//     const authHeader = new Headers();
//     authHeader.append("Content-Type", "application/json");
//     const requestOptions: RequestInit = {
//         method: "DELETE",
//         headers: authHeader,
//         credentials: 'include',
//     };
//     const response = await fetch(`${config.apiUrl}/v1/${entity}/${id}`, requestOptions);
//     if (response.ok) {
//         return (await response.json()) as T;
//     }
//     return Promise.reject("Invalid response from server.");
// }
export type UploadListener = (this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) => void;
async function upload<T>(entity: String, id: number, file: File, listener?: UploadListener): Promise<T> {

    interface RequestResult {
        ok: boolean;
        status: number;
        statusText: string;
        data: string;
        json: <T>() => T;
        headers: string;
    }
    function parseXHRResult(xhr: XMLHttpRequest): RequestResult {
        return {
            ok: xhr.status >= 200 && xhr.status < 300,
            status: xhr.status,
            statusText: xhr.statusText,
            headers: xhr.getAllResponseHeaders(),
            data: xhr.responseText,
            json: <T>() => JSON.parse(xhr.responseText) as T,
        };
    }
    function errorResponse(xhr: XMLHttpRequest, message: string | null = null): RequestResult {
        return {
            ok: false,
            status: xhr.status,
            statusText: xhr.statusText,
            headers: xhr.getAllResponseHeaders(),
            data: message || xhr.statusText,
            json: <T>() => JSON.parse(message || xhr.statusText) as T,
        };
    }

    return new Promise<T>((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        //xhr.addEventListener('progress', );
        xhr.open('PUT', `${config.apiUrl}/v1/${entity}/${id}`);
        // xhr.onreadystatechange = function () {
        //     if (xhr.readyState === XMLHttpRequest.DONE) {
        //         let data = JSON.parse(xhr.responseText);
        //         resolve(data as T);
        //     }            
        // };
        if (listener) {
            xhr.upload.onprogress = listener;
        }
        xhr.onload = evt => {
            const response = parseXHRResult(xhr);
            if (response.ok) {
                resolve(response.json<T>());
            }
            else {
                reject(response.data);
            }
        };
        xhr.onerror = evt => {
            reject(errorResponse(xhr, 'Failed to make request.'));
        };
        xhr.ontimeout = evt => {
            reject(errorResponse(xhr, 'Request took longer than expected.'));
        };

        const formData = new FormData();
        formData.append("upload", file);
        xhr.send(formData);
    });
    // const authHeader = new Headers();
    // authHeader.append("Content-Type", "application/json");
    // const formData = new FormData();
    // formData.append("file", file);
    // const requestOptions: RequestInit = {
    //     method: "PUT",
    //     headers: authHeader,
    //     credentials: 'include',
    //     body: formData,
    // };
    // const response = await fetch(`${config.apiUrl}/v1/${accountId}/${entity}/${id}`, requestOptions);
    // if (response.ok) {
    //     return (await response.json()) as T;
    // }
    // return Promise.reject("Invalid response from server.");
}
